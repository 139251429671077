<template>
  <div>
    <b-container fluid>
      <div class="d-flex justify-content-center" v-if="loading"><div class="spinner"></div></div>
      <div v-if="showTeam">
        <b-row align-h='center'>
          <b-col cols='12' md='8'>
            <h2 class='team-title'>Settings</h2>
            <b-card>
              <div class="mb-3">
                <h4>Team Name</h4>
                <b-form-input v-model="team.name" :placeholder="team.name"></b-form-input>
              </div>
              <div class="mb-3">
                <h4>Players</h4>
                <b-table responsive striped hover :items="team.players" :fields="playerFields" primary-key="name">
                <template slot="jerseyNumber" slot-scope="row">
                    <b-input type="number" class="form-control-sm"
                      style="width:5em"
                      v-model="row.item.jerseyNumber"
                      v-on:input="handleJerseyChange($event,row.item, row.index)"/>
                </template>
                <template slot="position" slot-scope="row">
                    <b-input type="text" class="form-control-sm"
                      style="width:5em"
                      v-model="row.item.position"
                      v-on:input="handlePositionChange($event,row.item, row.index)"/>
                </template>
              </b-table>
              </div>
              <div class="mb-3">
                <h4>Stat Categories</h4>
                <table class="table-responsive-md table table-striped">
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col">Name</th>
                      <th class="d-none d-md-table-cell" scope="col">Short Name</th>
                      <th class="d-table-cell d-md-none" scope="col">Abrv</th>
                      <th scope="col">Type</th>
                      <th scope="col">Default</th>
                    </tr>
                  </thead>
                  <draggable v-model="stats" tag="tbody">
                    <tr v-for="(stat, index) in stats" :key="stat.name">
                      <td><font-awesome-icon icon="bars"></font-awesome-icon></td>
                      <td><span @click="removeStat(index)" v-if="!stat.default" ><font-awesome-icon icon="minus-circle"></font-awesome-icon></span></td>
                      <td scope="row">{{ stat.name }}</td>
                      <td>{{ stat.shortName }}</td>
                      <td>{{ stat.type }}</td>
                      <td>{{ stat.default }}</td>
                    </tr>
                  </draggable>
                </table>
                <h4>Add new stat category</h4>
                <b-form inline>
                  <b-form-group
                    id="input-group-1"
                    label="Stat name:"
                    label-for="input-1"
                  >
                    <b-form-input
                      class="ml-md-2 mr-md-2"
                      id="input-1"
                      :formatter="toLower"
                      v-model="newStat.name"
                      type="text"
                      required
                      placeholder="ie. 'faceoffs'"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="input-group-2"
                    label="Short name:"
                    label-for="input-2"
                    class="ml-md-2 mr-md-2"
                  >
                    <b-form-input
                      id="input-2"
                      :formatter="toUpper"
                      v-model="newStat.shortName"
                      type="text"
                      required
                      placeholder="ie. 'FO'"
                      class="ml-md-2 mr-md-2"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-checkbox class="ml-md-2 mr-md-4" v-model="newStat.type">Show as percentage %</b-form-checkbox>
                  <b-button class="ml-md-2 mr-md-2" @click="addStat" variant="primary">Add</b-button>
                </b-form>
              </div>
              <div class="mb-3">
                <h4>Users</h4>
                <small>Coming soon</small>
              </div>
              <div class="mb-3">
                <h4>Invite Users</h4>
                <small>Coming soon</small>
              </div>
              <!-- <div class="mb-3">
                <h4>Users</h4>
                <b-list-group>
                  <b-list-group-item class="flex-column align-items-start">
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-0">Jayson Ambrose</h5>
                    </div>
                    <small>jaysonambrose@gmail.com</small>

                    <div class="mt-2">
                      <b-form inline>
                        <b-form-checkbox class="ml-md-2 mr-md-4" v-model="newStat.type">Can View</b-form-checkbox>
                        <b-form-checkbox class="ml-md-2 mr-md-4" v-model="newStat.type">Can Edit</b-form-checkbox>
                      </b-form>
                    </div>

                  </b-list-group-item>

                  <b-list-group-item href="#" class="flex-column align-items-start">
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1">List group item heading</h5>
                      <small class="text-muted">3 days ago</small>
                    </div>

                    <p class="mb-1">
                      Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.
                    </p>

                    <small class="text-muted">Donec id elit non mi porta.</small>
                  </b-list-group-item>

                  <b-list-group-item href="#" disabled class="flex-column align-items-start">
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1">Disabled List group item</h5>
                      <small class="text-muted">3 days ago</small>
                    </div>

                    <p class="mb-1">
                      Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.
                    </p>

                    <small class="text-muted">Donec id elit non mi porta.</small>
                  </b-list-group-item>
                </b-list-group>
                <b-form-input v-model="team.members" placeholder="{this.teamName}"></b-form-input>
              </div> -->
            </b-card>
          </b-col>
        </b-row>
        <b-row align-h='center'>
          <b-col cols='12' md='8' class="d-flex justify-content-center">
            <b-button class='mt-4 mb-5 mr-5' variant='primary' @click="saveSettings">Save Settings</b-button>
            <b-button class='mt-4 mb-5' variant='danger' @click='cancel'>Cancel</b-button>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <!-- </div> -->
  </div>
</template>

<script>

import { store } from '../store'
import TeamSnap from '@/mixins/TeamSnap'
import draggable from 'vuedraggable'

export default {
  name: 'TeamSettings',
  components: {
    draggable
  },
  mixins: [TeamSnap],
  data () {
    return {
      team: store.state.team,
      userProfile: {},
      backLink: '',
      teamLink: '',
      teamName: '',
      stats: [],
      statsFields: [],
      newStat: {},
      loading: true,
      showTeam: false,
      isManager: false
    }
  },
  head: {
    title () {
      return {
        inner: this.team.name
      }
    },
    meta () {
      return [
        { name: 'description', content: this.team.name, id: 'desc' }
      ]
    }
  },
  computed: {
  },
  async created () {
    this.backlink = '/team/' + this.$route.params.teamId
    this.teamLink = '/team/' + this.$route.params.teamId
    let teamId = this.$route.params.teamId
    await store.commit('setCurrentTeamId', teamId)
    await store.dispatch('bindTeamData')
    this.team = store.state.team
    let tsUser = await this.getUserInfo()
    console.log(this.team)
    console.log(tsUser)
    if (tsUser.managedTeamIds.includes(this.team.tsId)) {
      this.setStats()
      this.setNav()
      this.showTeam = true
      this.loading = false
      this.$emit('updateHead')
    } else {
      alert('Not a manager, sorry.')
    }
    this.playerFields = [
      {
        key: 'jerseyNumber',
        label: '#',
        'class': 'text-left'
      },
      {
        key: 'name',
        label: 'Player',
        'class': 'text-left text-nowrap',
        formatter: (value, key, item) => {
          return item.firstName + ' ' + item.lastName
        }
      },
      {
        key: 'position',
        label: 'Position',
        'class': 'text-left text-nowrap'
      }
    ]
    this.statsFields = [
      { key: 'name', label: 'Name' },
      { key: 'shortName', label: 'Abrv' },
      { key: 'type', label: 'Type' },
      { key: 'default', label: 'Default' },
      { key: 'actions', label: '' }
    ]
  },
  methods: {
    toLower(value) {
      if (value) {
        return value.toLowerCase()
      } else {
        return ''
      }
    },
    toUpper(value) {
      if (value) {
        return value.toUpperCase()
      } else {
        return ''
      }
    },
    handleJerseyChange (e, player, index) {
      let newNumber = parseInt(e)
      if (!isNaN(newNumber)) {
        this.team.players[index].jerseyNumber = newNumber
        this.team.players[index].missingJerseyNumber = false
        //Check if everyone has a jerseyNumber
        this.missingJerseyNumber = false
        this.team.players.forEach(player => {
          if (!player.jerseyNumber) {
            this.missingJerseyNumber = true
          }
        })
      }
    },
    handlePositionChange () {
      //Count for goalies
      this.goalieCount = 0
      this.team.players.forEach(player => {
        if (player.position === 'Goalie' || player.position === 'Goaltender') {
          this.goalieCount++
        }
      })
    },
    setNav () {
      if (this.$route.query.from === 'addteam') {
        store.commit('updateNav', {
          backLink: '/addteamdetail',
          teamLink: '/addteamdetail',
          teamName: 'Back'
        })
      } else {
        store.commit('updateNav', {
          backLink: '/team/' + this.$route.params.teamId,
          teamLink: '/team/' + this.$route.params.teamId,
          teamName: this.team.name
        })
      }
    },
    setStats () {
      console.log(this.team)
      if (this.team.statCategories.length === 0) {
        this.stats = [
          {
            name: 'goals',
            type: 'total',
            shortName: 'G',
            default: true
          },
          {
            name: 'shots',
            type: 'total',
            shortName: 'SOG',
            default: true
          },
          {
            name: 'penalties',
            type: 'total',
            shortName: 'PEN',
            default: true
          },
          {
            name: 'powerplays',
            type: 'total',
            shortName: 'PP',
            default: true
          }
        ]
      } else {
        this.stats = this.team.statCategories
      }
    },
    addStat (e) {
      e.preventDefault()
      var newStat = this.newStat
      if (newStat.type) {
        newStat.type = '%'
      } else {
        newStat.type = 'total'
      }
      this.stats.push({
        name: newStat.name,
        shortName: newStat.shortName,
        type: newStat.type,
        default: false
      })
      this.newStat = {}
    },
    removeStat (index) {
      this.stats.splice(index)
    },
    saveSettings () {
      store.commit('setCurrentTeam', this.team)
      store.dispatch('saveTeam').then(() => {
        this.$router.push({
          name: 'TeamDetail',
          params: { teamId: this.team.id }
        })
      })
    },
    cancel () {
      if (this.$route.query.from === 'addteam') {
        this.$router.push({
          name: 'AddTeam',
          params: { teamId: this.team.id }
        })
      } else {
        this.$router.push({
          name: 'TeamDetail',
          params: { teamId: this.team.id }
        })
      }
    }
  }
}


</script>

<style scoped>  /* "scoped" attribute limit the CSS to this component only */

.link {
  color: #007bff;
  cursor: pointer;
}

td:hover {
  cursor: grab;
}

td:active:hover {
  cursor: grabbing;
}

</style>